import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TextInput,
  SimpleForm,
  BulkDeleteWithUndoButton,
  useRecordContext,
} from 'react-admin';
import { FRONTEND_URL } from '../variables';
import { getIsDisabledByRole } from '../utils/get-disabled-by-role';
import { NumberInput } from '../inputs/number-input';
import { TranslatableInput } from '../inputs/translatable-input';

export const ApiEndpointsList = (props) => (
  <List {...props} perPage={50} exporter={false} hasCreate={false}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="order" />
      <TextField source="path" />
      <EditButton />
    </Datagrid>
  </List>
);

const Form = () => {
  return (
    <SimpleForm>
      <NumberInput
        source="order"
        helperText={
          <>
            Позиция относительно других эндпоинтов на <a href={`${FRONTEND_URL}/public-api`}>лендинге</a> в блоке
            Endpoints
          </>
        }
      />
      <TranslatableInput source="locales">
        <TextInput source="description" style={{ width: 470 }} defaultValue={null} />
      </TranslatableInput>
    </SimpleForm>
  );
};

const ApiEndpointsTitle = () => {
  const record = useRecordContext();
  return <span>API Endpoint {record?.path || ''}</span>;
};

export const ApiEndpointsEdit = () => {
  return (
    <Edit title={<ApiEndpointsTitle />}>
      <Form />
    </Edit>
  );
};
